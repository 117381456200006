import VueLazy from 'vue-lazyload';
// import Appscroll from '../components/scroll_wrap'
// import upDownSc from '../components/upDownScroll'
// import horSc from '../components/horSc'
import imageLoader from '../components/image-loader'

export default {
    //图片懒加载
    install(Vue) {
        Vue.use(VueLazy, {
            preLoad: 1.3,
            // error: "https://img.jimeimadou.com/common/icon/image-fail.png",
            loading: "/static/zhanwei.png",
            attempt: 1,
        });
        Vue.component('image-loader', imageLoader);
    }

}