/* @usage 20170811
 * var _getSign = require('utils/sign.js'),
       _wrap = 'test' // 参数转为字符串后加在前后的字符，非测试情况为'secret'
 * var sign = _getSign({
 *   method: 'taobao.tae.item.detail.get',
 *   timestamp: '2013-05-06 13:52:03',
 *   format: 'xml',
 *   app_key: 'test',
 *   v: '2.0',
 *   id: 'AAEkwBGKAAXszj-DOJ-KKVll',
 *   fields: 'itemInfo',
 *   sign_method: 'md5',
 *   session: 'test'
 * }, _wrap)
 * console.log(sign) // _wrap -> 'test' => 972BDE5CEA2BE55D6D86A2BAB2213025
*/

var TIME = require('./util')

var MD5 = require('./md5')
var G_Data = {},
  G_Wrap = 'EC25F62846E89A6362E2C9B10995F845'



function objFilter() { // 判断参数是否为非空对象
  var self, obj = G_Data,
    el, _bool = false
  if (Object.prototype.toString.call(obj) != '[object Object]') {
    return false
  }
  for (el in obj) {
    _bool = true
    break
  }
  return _bool
}

function obj2str() { // 将参数对象转换为按参数名升序排列的字符串
  var obj = G_Data,
    el, keys = [],
    arr, ret = G_Wrap
  for (el in obj) {
    keys.push(el)
  }
  keys = keys.sort()
  for (el in keys) {
    keys[el] += obj[keys[el]]
  }
  ret += (keys.join('') + ret)

  // console.log("升序排列的字符串", ret);
  return ret
}

function InitSign(obj, wrap) { // 入口
  var sign
  G_Data = obj
  G_Wrap = wrap || 'secret' //bb86d242e3b2aa7dc011bb3cd9d726ad
  if (objFilter()) {
    // sign = obj2str()
    sign = obj
    return MD5(sign).toUpperCase()
  } else {
    return ''
  }
}

//签名
function signParams(params) {

  var json_data = JSON.stringify(params);
  var secret_str = "EC25F62846E89A6362E2C9B10995F845"
  
  var time_str = TIME.formatTime();

  var join_str = json_data + time_str + secret_str;

  // console.log('加密----------------------------------------------');
  // console.log("join_str:",join_str);
  // console.log("time_str:",time_str);
  // console.log("secret_str:",secret_str);

  var sign_str = MD5(join_str).toUpperCase();

  // console.log("sign_str--:",sign_str);


  var last_params = {
    "data": json_data,
    "timestamp" : time_str
  };
  if (sign_str) {
    last_params["sign"] = sign_str;
  }
  return last_params;
}

module.exports = {
  signParams: signParams
}