import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
var sign_module = require('../../public/js/sign');
import qs from "qs"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    invited_url: '',
    invited_img: '',
	  token:'',
    nim: null,
    sessionList: [], //会话列表
    sessionUsers: {}, //会话用户名片列表
    stickTopSessions: {}, //置顶会话信息
    mutelist: [], //免打扰会话列表
    muteSessions: {}, //免打扰会话信息
    currentChatTo: '', // 正在聊天 sessionId
    messageArr: [], //消息列表
    total_unread_num: 0//消息未读数
  },
  getters: {
    getNim(state) {
      return state.nim
    },
    getCurrentChatTo(state) {
      return state.currentChatTo
    }
  },
  mutations: {
    setInvited(state, payload) {
      state.invited_url = payload.invite_seller_url;
      state.invited_img = payload.invite_user_img;
    },
    Init_SDK(state, payload) {
      state.nim = payload
    },
    //会话更新
    UpdateSession(state, payload) {
      let sessions = payload;
      let sessionList = state.nim.mergeSessions(state.sessionList, sessions);
      state.sessionList = sessionList;
      let total_unread_num = 0;
      sessionList.forEach(item => {
        item.isLastRead = state.nim.isMsgRemoteRead(item.lastMsg);
        total_unread_num += parseFloat(item.unread)
      });
      if (total_unread_num != 0) {
        state.total_unread_num = total_unread_num;
      } else {
        state.total_unread_num = 0;
      }
    },
    //删除会话
    deleteSessions(state, payload) {
      let session = payload;
      let sessionList = state.nim.cutSessionsByIds(state.sessionList, session);
      state.sessionList = sessionList;
    },
    //更新用户名片信息
    Update_SessionUsers(state, payload) {
      let sessionUsers = Object.assign({}, state.sessionUsers);
      let users = payload;
      users.forEach(item => {
        item.avatar = item.avatar || "https://img.jimeimadou.com/common/mt_ico.png";
        sessionUsers[item.account] = item
      });
      state.sessionUsers = sessionUsers
    },
    //初始化置顶会话
    SetStickTopSessions(state, payload) {
      let sessions = payload;
      sessions.forEach(item => {
        state.stickTopSessions[item.to] = item.isTop;
      })
    },
    //置顶会话更新
    StickTopSessions_Update(state, payload) {
      let stickTopSessions = Object.assign({}, state.stickTopSessions);
      let {
        session,
        isTop
      } = payload;
      stickTopSessions[session] = !isTop;
      state.stickTopSessions = stickTopSessions
    },
    //静音会话列表更新
    Mutelist_Update(state, payload) {
      let mutelist = [];
      let muteSessions = {};
      let {
        msg,
        act
      } = payload;
      if (act === "add") {
        mutelist = state.nim.mergeRelations(state.mutelist, msg)
      } else {
        mutelist = state.nim.cutRelations(state.mutelist, msg)
      }
      mutelist.forEach(item => {
        muteSessions[item.account] = true
      });
      state.mutelist = mutelist;
      state.muteSessions = muteSessions
    },
    SetCurrentChatMessage(state, payload) {
      let msgs = payload;
      msgs.forEach(item => {
        item.isLastRead = state.nim.isMsgRemoteRead(item)
      });
      state.messageArr = msgs.concat(state.messageArr)
    },
    CurrentChatTo_Change(state, payload) {
      let account = payload;
      if (account != state.currentChatTo) {
        state.messageArr = []
      }
      state.currentChatTo = account
    },
    MessageArr_Update(state, payload) {
      let msg = payload;
      let messageArr = state.nim.mergeMsgs(state.messageArr, msg);

      messageArr.forEach(item => {
        item.isLastRead = state.nim.isMsgRemoteRead(item)
      });
      state.messageArr = messageArr
    },
    //我撤回消息
    MessageArr_Delete_Mine(state, payload) {
      let msg = payload;
      let messageArr = state.messageArr;
      if (messageArr.length == 0) {
        return
      }
      let lastMsgIndex = messageArr.length - 1;
      for (let i = lastMsgIndex; i >= 0; i--) {
        if (messageArr[i].time == msg.time) {
          messageArr[i].type = "tip";
          messageArr[i].tipMsg = "你撤回了一条消息";
          break
        }
      }
      state.messageArr = messageArr
    },
    //对方撤回消息
    MessageArr_Delete_Other(state, payload) {
      let msg = payload;
      let messageArr = state.messageArr;
      if (messageArr.length == 0) {
        return
      }
      let lastMsgIndex = messageArr.length - 1;
      // console.log(msg,messageArr)
      for (let i = lastMsgIndex; i >= 0; i--) {
        if (messageArr[i].time == msg.deletedMsgTime) {
          messageArr[i].type = "tip";
          let nick = msg.deletedMsgFromNick || "对方";
          messageArr[i].tipMsg = nick + "撤回了一条消息";
          break
        }
      }
      state.messageArr = messageArr
    },
  },
  actions: {
    async getinvited(context, payload) {
      //console.log(payload);
      var initparams = {
        inviteCode: payload.inviteCode,
        channel: payload.channel
      };
      var params = sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      //console.log(params);
      var res = await axios.post(process.env.VUE_APP_URL + "/mobi/mote/invite", qs.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      var jsondata = JSON.parse(res.data.data);
	  console.log("生成图片返回的结果",jsondata)
      context.commit('setInvited', jsondata)
    },
    createSocketConnect(store,payload) {
      let nim = SDK.NIM.getInstance({
        // 初始化SDk
        // debug: true,
        db: false,
        appKey: "462c35595e864982543a72e9ccdc55be",
        account: payload.account,
        token: payload.token,
        promise: true,
        transports: ['websocket'],
        syncSessionUnread: true, // 同步未读数
        syncStickTopSessions: true, //会话置顶
        //连接成功
        onconnect: function() {
          console.log(1, "onconnect")
        },
        //断开重连
        onwillreconnect: function() {
          console.log(2, "onwillreconnect========断开重连======")
        },
        //丢失连接
        ondisconnect: function() {
          console.log(3, "ondisconnect")
        },
        //连接出错
        onerror: function(error) {
          console.log(4, "onerror", error)
        },
        //会话
        onsessions: function(sessions) {
          console.log(5, "onsessions会话列表", sessions);
          store.dispatch("UpdateSession", sessions);
          let accounts = sessions.map(item => item.to);
          let nim = store.getters.getNim;
          nim.getUsers({
            accounts,
            done: function(error, users) {
              if (!error) {
                store.dispatch("Update_SessionUsers", users)
              } else {
                console.log(error)
              }
            }
          })
        },
        //会话更新：收到消息、发送消息、设置当前会话、重置会话未读数 触发
        onupdatesession: function(session) {
          console.log(6, "onupdatesession会话更新", session);
          store.dispatch("UpdateSession", session);
          let accounts = [session.to];
          let nim = store.getters.getNim;
          nim.getUsers({
            accounts,
            done: function(error, users) {
              if (!error) {
                store.dispatch("Update_SessionUsers", users)
              } else {
                console.log(error)
              }
            }
          })
        },
        //置顶会话
        onStickTopSessions: function(topList) {
          console.log(7, "onStickTopSessions置顶会话", topList);
          store.dispatch("SetStickTopSessions", topList)
        },
        //静音会话
        onmutelist: function(mutelist) {
          console.log(8, "onmutelist静音会话", mutelist);
          store.dispatch("Mutelist_Update", {
            msg: mutelist,
            act: "add"
          })
        },
        //漫游消息
        onroamingmsgs: function(list) {
          console.log(9, "onroamingmsgs漫游消息", list)
        },
        //同步离线消息
        onofflinemsgs: function(msg) {
          console.log(10, "onofflinemsgs离线消息同步完成", msg)
        },
        //收到消息
        onmsg: function(msg) {
          console.log(11, "onmsg收到消息", msg);
          if(store.getters.getCurrentChatTo == msg.from){
            store.dispatch("MessageArr_Update", msg)
          }
        },
        //消息同步完成
        onsyncdone: function() {
          console.log(12, "onsyncdone所有消息同步完成")
        },
        onsysmsg:function(msg){
          console.log(13, "onsysmsg接收到系统消息",msg);
          if(store.getters.getCurrentChatTo == msg.from){
            //删除消息列表中撤回的消息
            store.dispatch("MessageArr_Delete_Other", msg)
          }
        },
      });
      store.commit("Init_SDK",nim);
    },
    UpdateSession(content, payload) {
      content.commit('UpdateSession', payload)
    },
    Update_SessionUsers(content, payload) {
      content.commit('Update_SessionUsers', payload)
    },
    SetStickTopSessions(content, payload) {
      content.commit('SetStickTopSessions', payload)
    },
    Mutelist_Update(content, payload) {
      content.commit('Mutelist_Update', payload)
    },
    StickTopSessions_Update(content, payload) {
      content.commit('StickTopSessions_Update', payload)
    },
    deleteSessions(content, payload) {
      content.commit('deleteSessions', payload)
    },
    SetCurrentChatMessage(content, payload) {
      content.commit('SetCurrentChatMessage', payload)
    },
    CurrentChatTo_Change(content, payload) {
      content.commit('CurrentChatTo_Change', payload)
    },
    MessageArr_Update(content, payload) {
      content.commit('MessageArr_Update', payload)
    },
    MessageArr_Delete_Mine(content, payload) {
      content.commit('MessageArr_Delete_Mine', payload)
    },
    MessageArr_Delete_Other(content, payload) {
      content.commit('MessageArr_Delete_Other', payload)
    },
  },
  modules: {}
})