import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: () => import( /*webpackChunkName: "home"*/ '../views/Home'),
	redirect: '/main',
	children: [{
			path: 'main',
			name: 'main',
			component: () => import( /*webpackChunkName: "main"*/ '../views/Main'),
			meta: {
				showTab: true
			},
			children: [{
					path: 'rank',
					name: 'rank',
					component: () => import( /*webpackChunkName: "main"*/ '../views/Rank'),
				},
				{
					path: 'album',
					name: 'album',
					component: () => import( /*webpackChunkName: "main"*/ '../views/Album'),
				}
			]
		},
		{
			path: 'plaza',
			name: 'plaza',
			component: () => import( /*webpackChunkName: "plaza"*/ '../views/Plaza'),
			meta: {
				showTab: true
			},
			children: []
		},
		{
			path: '/goodsdetail',
			name: 'goodsdetail',
			component: () => import( /*webpackChunkName: "goodsdetail"*/ '../views/Goodsdetail')
		},
		{
			path: '/goodsapply',
			name: 'goodsapply',
			component: () => import( /*webpackChunkName: "goodsdetail"*/ '../views/GoodsApply')
		},
		{
			path: 'order',
			name: 'order',
			component: () => import( /*webpackChunkName: "order"*/ '../views/OrderTab'),
			meta: {
				showTab: true
			},
		},
		{
			path: 'orderlist',
			name: 'orderlist',
			component: () => import( /*webpackChunkName: "order"*/ '../views/OrderList'),
			children: []
		},
		{
			path: 'commit',
			name: 'commit',
			component: () => import( /*webpackChunkName: "order"*/ '../views/order/OrderCommit'),
		},
		{
			path: 'orderdetail',
			name: 'orderdetail',
			component: () => import( /*webpackChunkName: "order"*/ '../views/order/OrderDetail'),
			children: []
		},
		{
			path: 'mine',
			name: 'mine',
			meta: {
				showTab: true
			},
			component: () => import( /*webpackChunkName: "mine"*/ '../views/Mine'),
			children: []
		},
		{
			path: 'shareCode',
			name: 'shareCode',
			meta: {
				showTab: true
			},
			component: () => import( /*webpackChunkName: "mine"*/ '../views/ShareCode'),
			children: []
		},
		{
			path: 'photoAuth',
			name: 'photoAuth',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/auth/photoAuth'),
		}, {
			path: 'infoAuth',
			name: 'infoAuth',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/auth/infoAuth'),
		},
		{
			path: 'waitAuth',
			name: 'waitAuth',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/auth/waitAuth')
		},
		{
			path: 'balance',
			name: 'balance',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/balance/balance'),
		},
		{
			path: 'login',
			name: 'login',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/login/login')
		},
		{
			path: 'sendGoodsDetail',
			name: 'sendGoodsDetail',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/sendGoodsDetail/sendGoodsDetail')
		},
		{
			path: 'commission',
			name: 'commission',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/commission/commission')
		},
		{
			path: 'inviteDetail',
			name: 'inviteDetail',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/inviteDetail/inviteDetail')
		},
		{
			path: 'withdraw',
			name: 'withdraw',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/balance/withdraw')
		},
		{
			path: 'settings',
			name: 'settings',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/settings/settings')
		},
		{
			path: 'addressList',
			name: 'addressList',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/userAddress/addressList')
		},
		{
			path: 'addAddress',
			name: 'addAddress',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/userAddress/addAddress')
		},
		{
			path: 'level',
			name: 'level',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/level/level')
		},
		{
			path: 'mjxAlbum',
			name: 'mjxAlbum',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/mjxAlbum/mjxAlbum')
		},
		{
			path: 'cardMessage',
			name: 'cardMessage',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/card/CardMessage'),
		},
		{
			path: 'editCard',
			name: 'editCard',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/card/EditCard'),
		},
		{
			path: 'xhsRename',
			name: 'xhsRename',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/card/XhsRename'),
		},
		{
			path: 'authSuccess',
			name: 'authSuccess',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/card/AuthSuccess'),
		},
		{
			path: 'videoDetail',
			name: 'videoDetail',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/videoDetail/videoDetail'),
		},
		{
			path: 'channelAuth',
			name: 'channelAuth',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/channel/ChannelAuth'),
		},
		{
			path: 'mjxAlbum',
			name: 'mjxAlbum',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/mjxAlbum/mjxAlbum')
		},
		{
			path: 'uploadMjx',
			name: 'uploadMjx',
			component: () => import( /*webpackChunkName: "mine"*/ '../views/userCenter/mjxAlbum/uploadMjx'),
		},
		{
			path: 'familyAlbum',
			name: 'familyAlbum',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/familyAlbum/familyAlbum'),
		},
		{
			path: 'createFamilyAlbum',
			name: 'createFamilyAlbum',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/familyAlbum/createAlbum'),
		},
		{
			path: 'editFamilyAlbum',
			name: 'editFamilyAlbum',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/familyAlbum/editAlbum'),
		},
		{
			path: 'zhongcaoAlbum',
			name: 'zhongcaoAlbum',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/zhongcaoAlbum/zhongcaoAlbum'),
		},
		{
			path: 'uploadZhongcao',
			name: 'uploadZhongcao',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/zhongcaoAlbum/uploadZhongcao'),
		},
		{
			path: 'uploadZhutu',
			name: 'uploadZhutu',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/zhongcaoAlbum/uploadZhutu'),
		},
		{
			path: 'modelDetail',
			name: 'modelDetail',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/modelDetail/modelDetail'),
		},
		{
			path: 'noviceCourse',
			name: 'noviceCourse',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/noviceCourse/noviceCourse'),
		},
		{
			path: 'aboutUs',
			name: 'aboutUs',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/aboutUs'),
		},
		{
			path: 'newNotice',
			name: 'newNotice',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/newNotice'),
		},
		{
			path: 'feedback',
			name: 'feedback',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/feedback'),
		},
		{
			path: 'noticeSetting',
			name: 'noticeSetting',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/noticeSetting'),
		},
		{
			path: 'inform',
			name: 'inform',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/inform'),
		},
		{
			path: 'smsSetting',
			name: 'smsSetting',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/settings/smsSetting'),
		},
		{
			path: 'userInfo',
			name: 'userInfo',
			component: () => import( /*webpackChunkName: "mine"*/
				'../views/userCenter/userInfo/userInfo'),
		},
		{
			path: 'message',
			name: 'message',
			component: () => import( /*webpackChunkName: "message"*/ '../views/Message'),
			meta: {
				showTab: true
			},
		},
		{
			path: 'chat',
			name: 'chat',
			component: () => import( /*webpackChunkName: "message"*/ '../views/message/ChatTo'),
		},
		{
			path: 'editReply',
			name: 'editReply',
			component: () => import( /*webpackChunkName: "message"*/ '../views/message/EditReply'),
		},
		{
			path: 'invite',
			name: 'invite',
			component: () => import( /*webpackChunkName: "message"*/ '../views/Invite'),
		},
		{
			path: 'albumDetail',
			name: 'albumDetail',
			component: () => import( /*webpackChunkName: "message"*/ '../views/albumDetail/albumDetail'),
		}
	]
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});
// router.beforeEach((to,from,next)=>{
//   console.log(to);
//   console.log(from);
//   next()
// })
export default router
