import Vue from 'vue';
import {
	Popup,
	Toast,
	Field,
	Picker,
	Checkbox,
	Icon,
	Switch,
	Uploader,
	Tab,
	Tabs,
	Loading,
	Dialog,
	Button,
	NavBar,
	Cascader,
	Swipe,
	SwipeItem,
	ImagePreview,
	DatetimePicker,
	CheckboxGroup,
	Popover,
	Image as VanImage
} from 'vant';

Vue.use(Popup);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Checkbox);
Vue.use(Icon);
Vue.use(Switch);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Cascader);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ImagePreview);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popover);
Vue.use(VanImage);
