<template>
	<div class="task-tag-con">
		<img v-if="task_require==4" class="xhs-icon" :src="xhsType[xhs_prom_type]"></img>
		<div class="type-tip type2" v-if="task_require==1">买家秀</div>
		<div class="type-tip type2" v-if="task_require==3">主图/详情页</div>
		<div class="type-tip type2" v-if="task_require==2">
			<div class="zc-icon">
				<img class="zc-img" src="https://img.jimeimadou.com/common/resource/goods_icon/zhogncao%403x.png"></img>
				<div>种草</div>
			</div>
		</div>
		<template v-if="xhs_prom_type!=3">
			<div class="type-tip type3" v-if="task_type==1">赠拍</div>
			<div class="type-tip type4" v-if="task_type==2">寄拍</div>
			<div class="type-tip type1" v-if="task_require_sub==1">口播介绍</div>
			<div class="type-tip type1" v-if="task_require_sub==2">开箱测评</div>
			<div class="type-tip type1" v-if="task_require_sub==3">时尚穿搭</div>
		</template>
	</div>
</template>

<script>
	export default{
		props:{
			task_require:{
				type:[String,Number],
				value:0
			},
			task_type:{
				type:[String,Number],
				value:0
			},
			task_require_sub:{
				type:[String,Number],
				value:0
			},
			xhs_prom_type:{
				type:[String,Number],
				value:0
			},
		},
		data(){
			return{
				xhsType:this.xhsType
			}
		}
	}
</script>

<style lang="scss" scoped>
	.task-tag-con{
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
	.xhs-icon{
		height: 30px;
		width: 88px;
		display: block;
		vertical-align: middle;
		border-radius: 5px;
		margin-right: 6px;
	}
	.type-tip{
		line-height: 30px;
		height: 30px;
		padding: 0 8px;
		border-radius: 5px;
		margin-right: 6px;
		color: #fff;
		font-size: 22px;
	}
	.zc-icon{
		display: flex;
		align-items: center;
		.zc-img{
			width: 14px;
			height: 14px;
			display: block;
			margin-right: 5px;
		}
	}
	.type-tip.type1 {
		background-color: #8AE6A8;
	}
	
	.type-tip.type2 {
		background-color: #FFEBEF;
		color: #FF6685;
	}
	
	.type-tip.type3 {
		background-color: #000;
	}
	
	.type-tip.type4 {
		background-color: #FF6685;
	}
</style>
