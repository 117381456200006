<template>
	<div class="null">
		<img class="null-img" src="https://img.jimeimadou.com/common/resource/item_list_icon/empty%403x.png" >
		<div class="null-text">{{title}}</div>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:"暂无数据"
			}
		},
		data(){
			return{
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.null{
		height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.null-img{
			width: 280px;
		}
		.null-text{
			font-size: 26px;
			color: #aaa;
			margin-top: 30px;
		}
	}
</style>
