import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plu from './plugin/index'
import "./assets/css/iconfont.css"
import sign_module from "../public/js/sign.js"
import './config/components';
import './util/derective';
import qs from "qs";
import axios from "axios";

import Storage from 'vue-ls';
import storageOptions from './config/storage';
import VueClipboard from 'vue-clipboard2';
import imageLoader from './components/image-loader.vue'
import empty from './components/empty.vue'
import taskTag from "./components/task-tag";

Vue.use(plu);
Vue.use(Storage, storageOptions);
Vue.use(VueClipboard);

Vue.component("image-loader", imageLoader);
Vue.component("empty", empty);
Vue.component("taskTag", taskTag);

Vue.config.productionTip = false;
//是否打开console.log调试
Vue.config.openLog = false;

if (!Vue.config.openLog) {
	console.log = () => {}
}

Vue.prototype.sign_module = sign_module;


Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$addParams = function(params) {
	let newParams = sign_module.signParams(params)
	newParams["_platform"] = "mobi";
	newParams["_version"] = "1.0.0";
	return newParams
}


Vue.prototype.xhsType = {
	"1": "https://img.jimeimadou.com/common/icon/xhs_01.png",
	"2": "https://img.jimeimadou.com/common/icon/xhs_02.png",
	"3": "https://img.jimeimadou.com/common/icon/xhs_03.png"
};
Vue.prototype.channels = {
	"0": {
		icon: "https://img.jimeimadou.com/common/resource/step_icon/step1%403x.png",
		name: "其他",
		type:0,
	},
	"1": {
		icon: "https://img.jimeimadou.com/common/icon/channel01.png",
		name: "淘宝逛逛",
		type:1,
	},
	"2": {
		icon: "https://img.jimeimadou.com/common/icon/channel07.png",
		name: "京东plus",
		type:2,
	},
	"3": {
		icon: "https://img.jimeimadou.com/common/icon/channel02.png",
		name: "小红书",
		type:3,
	},
	"4": {
		icon: "https://img.jimeimadou.com/common/icon/channel03.png",
		name: "抖音",
		type:4,
	},
	"5": {
		icon: "https://img.jimeimadou.com/common/icon/channel04.png",
		name: "快手",
		type:5,
	},
	"6": {
		icon: "https://img.jimeimadou.com/common/icon/channel06.png",
		name: "微博",
		type:6,
	},
	"7": {
		icon: "https://img.jimeimadou.com/common/icon/channel05.png",
		name: "哔哩哔哩",
		type:7,
	}
};
Vue.prototype.mdTypes = {
	0: "不限",
	101: "男模",
	102: "女模",
	103: "童模",
	104: "中老年女模",
	105: "中老年男模",
	106: "情侣模特",
	107: "亲子模特",
	108: "宠模",
};
Vue.prototype.sexType = {
	"0": "不限",
	"1": "男",
	"2": "女"
};

Vue.prototype.promType = {
	"1": "图文",
	"2": "短视频",
	"3": "代发"
};

Vue.prototype.levels = {
	1: {
		icon: "https://img.jimeimadou.com/user/img/vip_images/level01.png",
		text: "新手麻豆"
	},
	2: {
		icon: "https://img.jimeimadou.com/user/img/vip_images/level02.png",
		text: "初级麻豆"
	},
	3: {
		icon: "https://img.jimeimadou.com/user/img/vip_images/level03.png",
		text: "专业麻豆"
	},
	4: {
		icon: "https://img.jimeimadou.com/user/img/vip_images/level04.png",
		text: "优质麻豆"
	}
}
Vue.prototype.getFileURL = function(file) {
	let url = null
	if (window.createObjectURL != undefined) {
		//basic
		url = window.createObjectURL(file)
	} else if (window.URL != undefined) {
		//mozilla(firefox)
		url = window.URL.createObjectURL(file)
	} else if (windoe.webkitURL != undefined) {
		//webkit or chrome
		url = window.webkitURL.createObjectURL(file)
	}
	return url
}


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')


router.afterEach((to, from) => {
	// 每次进入路由都会触发
	if (window._hmt) {
		if (to.path) {
			window._hmt.push(['_setAutoPageview', false]);
			window._hmt.push(['_trackPageview', '/template/displayTemplate/dist/index.html#' + to
				.fullPath
			]); // 如果不是根路径，需要指定第二个参数的路径
		}
	}
});

//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
    let imgURL = binding.value;//获取图片地址
    if (imgURL) {
        let exist = await imageIsExist(imgURL);
        if (exist) {
            el.setAttribute('src', imgURL);
        } else{
			el.setAttribute('src', "https://img.jimeimadou.com/common/icon/image-fail.png");
		}
    }
})
/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
    return new Promise((resolve) => {
        var img = new Image();
        img.onload = function () {
            if (this.complete == true){
                resolve(true);
                img = null;
            }
        }
        img.onerror = function () {
            resolve(false);
            img = null;
        }
        img.src = url;
    })
}



Vue.mixin({
	beforeCreate() {
		const options = this.$options;

		this.$hm = options.parent && options.parent.$hm ?
			options.parent.$hm :
			(function(window) {
				if (!window._hmt) {
					return;
				}
				return Object.assign({}, window._hmt, { // 统计页面的点击事件
					click: (category, val) => {
						const userInfo = store.getters.userInfo || {};
						return window._hmt.push([
							'_trackEvent',
							category,
							'click',
							userInfo.loginName || 'notLogin',
							val
						]);
					}
				});
			})(window);
	}
});
