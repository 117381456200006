<template>
	<van-image style="background-color: transparent;" :width="width" :height="height" lazy-load :src="src">
		<template v-slot:loading>
			<img v-if="hasLoadingGif" class="gif" src="https://img.jimeimadou.com/common/images/loading.gif">
			<img v-else class="zhanwei" src="https://img.jimeimadou.com/common/images/default.png">
		</template>
<!-- 		<template v-slot:error>
			<img class="gif" src="https://img.jimeimadou.com/common/images/loading.gif">
		</template> -->
	</van-image>
</template>

<script>
	export default {
		name: "image-loader",
		props: {
			hasLoadingGif: {
				type: Boolean,
				default: false
			},
			src: {
				type: String,
				default: ""
			},
			height:{
				type: [Number,String],
				default: "100%"
			},
			width:{
				type: [Number,String],
				default: "100%"
			}
		},
		data() {
			return {
		
			};
		},
		methods: {
		
		}
	}
</script>

<style lang="scss" scoped>
	.gif {
		width: 60px;
		height: 60px;
		display: block;
		object-fit: content;
	}

	.zhanwei {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
</style>
