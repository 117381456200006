<template>
	<div id="myapp">
		<router-view></router-view>
	</div>
</template>

<script>
	import store from './store'
	export default {
		methods:{
			getyxtoken(){
				let sendData = {
					access_token:localStorage.getItem('access_token'),
				};
				let params = this.$addParams(sendData);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/user/getyxtoken",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
				).then((res)=>{
					if (res.data.status == 'y') {
						let result = JSON.parse(res.data.data);
						console.log("云信token返回结果",result);
						let {accid,token} = result;
						//连接socket
						this.$store.dispatch('createSocketConnect',{
							account:accid,
							token:token
						});
					}
				})
			}
		},
		created(){
			if(localStorage.getItem('access_token')){
				this.getyxtoken()
			}
		}
	}
</script>

<style>
	#myapp {
		height: 100vh;
		width: 100vw;
		background-color: #f2f3f7;
	}
	.bg-img{
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.container-wrapper{
		width: 100vw;
		height: 100vh;
		background-color: #fff;
	}
	.nomore{
		text-align: center;
		line-height: 40px;
		padding-top: 30px;
		padding-bottom: 50px;
		color: #aaa;
		font-size: 26px;
	}
</style>
